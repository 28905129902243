import { useState } from 'react';

const MobileDeviceTypeButton = ({ title }) => {
    const handleButtonClick = () => {
        // Detect the user's device type
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Redirect for Android devices
            window.open('https://play.google.com/store/apps/details?id=com.oneinthebillions.itrood&hl=en&gl=US', '_blank');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect for iOS devices
            window.open('https://apps.apple.com/app/itrood/', '_blank');
        } else {
            // Redirect for desktop/laptop (Mac or other)
            window.open('https://app.itrood.com', '_blank');
        }
    };

    const handleOpenButton = () => {
        window.open('https://watch.itrood.com', '_blank');
    };

    return (
        <div
            className='cursor-pointer hover:rounded-[12px] rounded-[8px] border border-blue-500 hover:bg-[#006fde] bg-[#0274e6] p-2 px-6 text-white text-[12px] hover:font-bold w-[100px] items-center justify-center text-center'
            onClick={title === 'Install' ? handleButtonClick : handleOpenButton}
        >
            {title}
        </div>
    );
};

export default MobileDeviceTypeButton;