import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../LoadingSpinner.js";
import mpesa from '../assets/images/mpesa.png';
import airtelmoney from '../assets/images/airtelmoney.jpg';
import tigopesa from '../assets/images/tigopesa.png';
import halopesa from '../assets/images/halopesa.png';
import simbanking from '../assets/images/simbanking.jpg';
import tick from '../assets/images/tick.png';
import send from '../assets/images/send.png';
import { useParams } from "react-router-dom";
import { logo } from "../assets/index.js";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';

const OrderPayment = () => {
    const { orderId } = useParams();
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [orderDetails, setOrderDetails] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [retries, setRetries] = useState(0);
    const [fetchLoad, setFetchLoad] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [isQROpen, setIsQROpen] = useState(false);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    const fetchOrderDetails = async () => {
        try {
            const response = await axios.get(`https://api.itrood.co.tz/api/v1/order-details/${orderId}`);
            const orderData = response.data.data;
            console.log("Order Details:", orderData);
            setOrderDetails(orderData);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setNotFound(true);
                    toast.error("Order not found. Please check the order ID.");
                } else {
                    toast.error(`Error fetching order details: ${error.response.statusText}`);
                }
            } else if (error.request) {
                console.error("No response received:", error.request);
                toast.error("No response from server. Please try again later.");
            } else {
                console.error("Error:", error.message);
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            setFetchLoad(false);
        }
    };

    useEffect(() => {
        if (orderId) {
            fetchOrderDetails();
        }
    }, [orderId]);

    const handlePhoneChange = (e) => {
        e.persist();
        setPhone(e.target.value);
    };

    const handleCopyToClipboard = (event) => {
        const textToCopy = orderId;
        navigator.clipboard.writeText(textToCopy);
        toast.success('Order transaction reference ID has been copied to clipboard');
    };

    const handleSimbankingPayment = async () => {
        setLoading(true);

        const orderPrice = orderDetails?.total_cost;

        if (!orderId || !orderPrice) {
            setLoading(false);
            toast.error('Oops, Something went wrong. Order information is missing!');
            return;
        }

        const orderNumber = orderId;
        const data = {
            id: orderId,
            total_cost: orderPrice,
            order_number: orderNumber
        };

        try {
            const response = await axios.post('https://api.itrood.co.tz/api/katy/registration', data);
            // console.log(response.data);
            if (response.data.code === 200) {
                // console.log(response.data);
            };
            toast.success('Awesome, You can now pay with SimBanking!');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Oops, Something wrong, Try again selecting the option!');
        } finally {
            setLoading(false);
        }
        setLoading(false);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://api.itrood.co.tz/api/getToken');
            // console.log('Token response:', response.data);

            if (response.data.statusCode === 200) {
                const accessToken = response.data.data.accessToken;

                const requestData = {
                    accountNumber: phone,
                    amount: orderDetails?.total_cost,
                    currency: 'TZS',
                    externalId: '128',
                    provider: paymentMethod,
                    TYRFID: orderId,
                    token: accessToken
                };

                // console.log('Request Data:', requestData);

                const paymentResponse = await axios.post('https://api.itrood.co.tz/api/checkout', requestData);
                // console.log('Payment request sent:', paymentResponse.data);

                if (paymentResponse.data.success) {
                    toast.success('Payment request sent to your phone');

                    const paymentData = {
                        orderID: orderId,
                        code: paymentResponse.data.success,
                        amount: orderDetails?.total_cost,
                        ReferenceID: orderId,
                        ResponseCode: paymentResponse.data.transactionId,
                        CustomerMSISDN: phone,
                        ResponseDescription: paymentResponse.data.message,
                        ResponseStatus: paymentResponse.data.success
                    };

                    const response = await axios.post('https://api.itrood.co.tz/api/v1/sale/mobilePayment', paymentData);
                    // console.log('Mobile payment response:', response.data);

                    // Refresh the page to reflect the changes
                    window.location.reload();

                    // Close the window and navigate to the home screen
                    window.location.href = "/";
                } else {
                    toast.error('Payment Error. Please go to the order');
                }
            } else {
                toast.error('Error while getting token. Please try again');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Axios error (e.g., network error, timeout)
                console.error('Axios Error:', error.message);
            } else {
                // Non-Axios error
                console.error('Error:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {notFound && (
                <div className="h-screen w-screen flex flex-col justify-center items-center text-center">
                    <img src={logo} className='w-[100px] m-2' />
                    <p className="text-lg text-[#dd3939] mx-2">404! Order not found.</p>
                    <p className="text-sm text-[#2c3946] mx-2">Please check the order ID or go back!</p>
                </div>
            )}
            {fetchLoad && (
                <div className="h-screen w-screen flex justify-center items-center">
                    <img src={logo} className='w-[100px]' />
                </div>
            )}
            {orderDetails?.payment_status === 1 && (
                <div className="h-screen w-screen flex flex-col justify-center items-center">
                    <img src={logo} className='w-[100px]' />
                    <p className="text-lg text-[#69dd39] m-2">Order already paid</p>
                </div>
            )}
            {!fetchLoad && orderDetails?.payment_status === 0 && (
                <div className="fixed z-50 inset-0 py-0 flex flex-col items-center justify-end bg-black/80">
                    <div className="w-full relative min-h-min overflow-x-hidden overflow-y-visible bg-white text-black rounded-sm shadow-lg">
                        <div className="w-full py-10">
                            <div className="text-center flex flex-col gap-y-10 items-center">
                                <img src={logo} className='w-[100px]' />
                                <img src={send} alt='Send' layout="intrinsic" height='100px' width='100px' className="rounded-xl" />
                                <div>
                                    <p className="text-lg text-[#042D5E]">Choose payment method</p>
                                    <p className="text-md font-semibold text-orange-500">Order ID : {orderId}</p>
                                    <p className="text-lg font-bold text-[#042D5E]">Total : TZs {orderDetails?.total_cost.toLocaleString()}</p>
                                    <button onClick={() => setIsDetailsOpen(true)} className={`h-[40px] w-[110px] mx-1 p-1 my-4 rounded-lg shadow-md`}>
                                        Order details
                                    </button>
                                    <Dialog open={isDetailsOpen} onClose={() => setIsDetailsOpen(false)} className="relative z-50">
                                        <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4 overflow-y-auto text-white" aria-hidden="true">
                                            <DialogPanel className="flex flex-col items-center max-w-[512px] space-y-4 border bg-white/30 backdrop-blur-md px-10 py-6 rounded-2xl">
                                                <DialogTitle className="font-bold text-[20px] text-center">Order details</DialogTitle>
                                                <div>
                                                    <div>
                                                        <div>
                                                            Client: <strong>{orderDetails?.customer.name}</strong>
                                                        </div>
                                                        <div>
                                                            Restaurant: <strong>{orderDetails?.place.name}</strong>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {orderDetails?.food?.length > 0 ? (
                                                            orderDetails.food.map((foodItem) => (
                                                                <div key={foodItem.id} className="border-b py-2 flex justify-start items-center min-w-[200px]">
                                                                    <img className="h-[50px] w-[50px] shrink-0 object-center rounded-xl m-2" src={`https://api.itrood.co.tz/images/food/${foodItem.banner}`} />
                                                                    <div>
                                                                        <div className="font-medium">{foodItem.menu_name}</div>
                                                                        <div>Size: {foodItem.size}</div>
                                                                        <div>Quantity: {foodItem.pivot.quantity}</div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div>No food items ordered.</div>
                                                        )}
                                                    </div>
                                                    {orderDetails?.drinks?.length > 0 && (
                                                        <div className="mt-4">
                                                            {orderDetails.drinks.map((drinkItem) => (
                                                                <div key={drinkItem.id} className="border-b py-2 flex justify-start items-center min-w-[200px]">
                                                                    <img className="h-[50px] w-[50px] shrink-0 object-center rounded-xl m-2" src={`https://api.itrood.co.tz/images/drinks/${drinkItem.image}`} />
                                                                    <div>
                                                                        <div className="font-medium">{drinkItem.name}</div>
                                                                        <div>Size: {drinkItem.size}</div>
                                                                        <div>Quantity: {drinkItem.pivot.quantity}</div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <div className="text-center font-bold mt-2">
                                                        Total Cost: {orderDetails?.total_cost}
                                                    </div>
                                                </div>
                                            </DialogPanel>
                                        </div>
                                    </Dialog>
                                    {/* <button onClick={() => setIsQROpen(true)} className={`h-[40px] w-[110px] mx-1 p-1 my-4 rounded-lg shadow-md`}>
                                        QR Code
                                    </button> */}
                                    <Dialog open={isQROpen} onClose={() => setIsQROpen(false)} className="relative z-50">
                                        <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4 overflow-y-auto" aria-hidden="true">
                                            <DialogPanel className="flex flex-col items-center max-w-[512px] space-y-4 border bg-white/30 backdrop-blur-md p-12 rounded-2xl">
                                                <DialogTitle className="font-bold text-[20px] text-center text-white">QR Code</DialogTitle>

                                            </DialogPanel>
                                        </div>
                                    </Dialog>
                                </div>
                                <div className="gap-4 justify-center p-2 flex flex-wrap">
                                    {/* Vodacom goes here... */}

                                    <div>
                                        {/* AirtelMoney */}
                                        {paymentMethod === 'airtel' && (<div className='p-1 m-1' style={{ zIndex: 1 }}><img src={tick} height='30' width='30' className="rounded-xl" /></div>)}
                                        <div
                                            className={`m-1 rounded-xl h-[100px] w-[150px] flex justify-center items-center cursor-pointer ${'bg-[#ee1b24] text-white'
                                                }`}
                                            onClick={() => setPaymentMethod('airtel')}
                                        >
                                            <img src={airtelmoney} alt='AirtelMoney' layout="intrinsic" className="rounded-xl" />
                                        </div>
                                    </div>

                                    <div>
                                        {/* Tigopesa */}
                                        {paymentMethod === 'tigo' && (<div className='p-1 m-1' style={{ zIndex: 1 }}><img src={tick} height='30' width='30' className="rounded-xl" /></div>)}
                                        <div
                                            className={`m-1 rounded-xl border h-[100px] w-[150px] flex justify-center items-center cursor-pointer ${'bg-white'
                                                }`}
                                            style={{ borderWidth: 2, borderColor: '#0b2e62' }}
                                            onClick={() => setPaymentMethod('tigo')}
                                        >
                                            <img src={tigopesa} alt='Tigopesa' layout="intrinsic" className="rounded-xl" />
                                        </div>
                                    </div>

                                    <div>
                                        {/* Halopesa */}
                                        {paymentMethod === 'halopesa' && (<div className='p-1 m-1' style={{ zIndex: 1 }}><img src={tick} height='30' width='30' className="rounded-xl" /></div>)}
                                        <div
                                            className={`m-1 rounded-xl border h-[100px] w-[150px] flex justify-center items-center cursor-pointer ${'bg-white'
                                                }`}
                                            style={{ borderWidth: 2, borderColor: '#fd7f12' }}
                                            onClick={() => setPaymentMethod('halopesa')}
                                        >
                                            <img src={halopesa} alt='Halopesa' layout="intrinsic" className="rounded-xl" />
                                        </div>
                                    </div>

                                    <div onClick={handleSimbankingPayment}>
                                        {/* SimBanking */}
                                        {paymentMethod === 'simbanking' && (<div className='p-1 m-1' style={{ zIndex: 1 }}><img src={tick} height='30' width='30' className="rounded-xl" /></div>)}
                                        <div
                                            className={`m-1 rounded-xl h-[100px] w-[150px] flex justify-center items-center cursor-pointer ${'bg-[#69dd39] text-white'
                                                }`}
                                            onClick={() => setPaymentMethod('simbanking')}
                                        >
                                            <img src={simbanking} alt='SimBanking' layout="intrinsic" className="rounded-xl" />
                                        </div>
                                    </div>
                                </div>

                                {paymentMethod == 'simbanking' && (
                                    <div className="flex flex-col w-full items-center">
                                        <div className='flex flex-row gap-1'>
                                            {loading ? (
                                                <div
                                                    style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderColor: '#69dd39', borderWidth: 2, }}
                                                    className="cursor-pointer text-[#69dd39] py-4 px-20 max-sm:px-[48px] text-base font-normal"
                                                >
                                                    <LoadingSpinner />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderColor: '#69dd39', borderWidth: 2, }}
                                                    className="cursor-pointer text-[#69dd39] font-bold py-4 px-20 max-sm:px-6 text-base"
                                                >
                                                    {orderId}
                                                </div>
                                            )}
                                            <div
                                                onClick={handleCopyToClipboard}
                                                style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, borderColor: '#69dd39', borderWidth: 2, backgroundColor: '#69dd39' }}
                                                className="cursor-pointer text-white py-4 px-24 max-sm:px-10 text-base font-normal"
                                            >
                                                Copy
                                            </div>
                                        </div>

                                        <div className=''>
                                            <div className='pl-10 mt-2 flex flex-col' style={{ textAlign: 'left' }}>
                                                <p className='p-1 font-bold'>Follow the steps to pay with SimBanking App:</p>
                                                <p>1. Copy and then open SimBanking App</p>
                                                <p>2. Select services</p>
                                                <p>3. Search for iTrood</p>
                                                <p>4. Enter Payment Reference NO: ({orderId})</p>
                                                <p>5. Select account to debt</p>
                                                <p>6. Confirm transaction</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {paymentMethod !== 'simbanking' && (
                                    <input
                                        value={phone}
                                        type="number"
                                        placeholder="255XXXXXX"
                                        onChange={handlePhoneChange}
                                        className="px-4 py-2 border border-blue rounded-md placeholder-text-sm"
                                    />
                                )}

                                {paymentMethod !== 'simbanking' && (
                                    <>
                                        {loading ? (
                                            <div className="bg-blue-500 flex flex-row justify-center gap-x-3 rounded-lg items-center py-4 px-20 text-white">
                                                <LoadingSpinner /> <p>Making payment...</p>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={handleSubmit}
                                                className="bg-blue-500 cursor-pointer rounded-lg text-white py-4 px-20 text-base font-normal"
                                            >
                                                Make Payment
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderPayment;