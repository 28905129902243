import React, { useState } from 'react';
import { logo, close, menu } from '../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DeviceTypeButton from './DeviceTypeButton';
import MobileDeviceTypeButton from './MobileDeviceTypeButton';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));
  const [toggle, setToggle] = useState(false);

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(item => `/${item.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  return (
    <div className='z-10 w-screen flex gap-4 p-2 absolute bg-transparent h-[100px] px-4 justify-center'>
      <div className='flex -z-10 absolute top-0 inset-0 bg-gradient-to-b from-[#000000ee] from-10% h-full' />
      <div className='flex w-[1080px] max-md:w-full items-center justify-between'>
        <DeviceTypeButton title='Open' />
        <Link to={`/`} className='cursor-pointer'>
          <img src={logo} className='w-[170px]' />
        </Link>
        <DeviceTypeButton title='Install' />

        <div className="md:hidden gap-2 flex justify-end items-center">
          <MobileDeviceTypeButton title='Open' />
          <MobileDeviceTypeButton title='Install' />
        </div>
      </div>
    </div>
  )
};

export const navLinks = [
  {
    id: "open",
    name: "Open",
    title: "Open",
  },
  {
    id: "install",
    name: "Install",
    title: "Install",
  }
]

export default Navbar;