import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

document.querySelectorAll('.activeImageContainer').forEach(container => {
  const image = container.querySelector('.activeImage');

  container.addEventListener('mousemove', (e) => {
    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const moveX = (x / rect.width) * 20 - 10;
    const moveY = (y / rect.height) * 20 - 10;

    image.style.transform = `scale(1.2) translate(${moveX}px, ${moveY}px)`;
  });

  container.addEventListener('mouseleave', () => {
    image.style.transform = 'scale(1)';
  });
});
