import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"
import { hero, hero2, hero3, hero4, hero5, hero6, tv, offline, kids, watch, restaurants, Restaurant1, Restaurant2, Restaurant3, Restaurant4, Restaurant5, Restaurant6, Restaurant7, Restaurant8, Stream, Resale, Release, SmartTv, devices, appstore, playstore, submit } from '../assets';
import { Link } from 'react-router-dom';
import RestaurantGallery from '../components/RestaurantGallery';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
import ActiveImage from '../components/ActiveImage';
import Navbar from '../components/Navbar';
import { toast } from 'react-toastify';

const Home = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const images = [hero3, hero2, hero, hero6];
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const titles = [
    'iTrood',
    'iTrood',
    'iTrood',
    'iTrood',
  ];
  const subtitles = [
    'Platforms',
    'Stream',
    'Resale',
    'Release',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSliderIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleSliderChange = (index) => {
    setSliderIndex(index);
  };

  const handleEmailSubmit = () => {
    // Show toast message
    toast.success('Email capture succeeded. Now you\'re being redirected to the app.');

    // Redirect after a short delay to allow the user to see the toast message
    setTimeout(() => {
      window.location.href = `https://app.itrood.com/?email=${encodeURIComponent(email)}`;
    }, 2000);
  };

  return (
    <div className='h-full'>
      <section id='#hero' className='h-full'>
        <div className='relative h-full'>
          <div className="flex absolute inset-0 h-[20%] bg-gradient-to-b from-black from-2%" />
          {images.map((image, index) => (
            <img
              key={index}
              alt='iTrood'
              src={image}
              className={`slider-image inset-0 w-full h-full object-cover transition-opacity ${index === sliderIndex ? 'active' : ''}`}
            />
          ))}
          <div className='flex absolute bottom-0 inset-0 bg-gradient-to-t from-[#000000] from-1%' />
          <div className='flex w-[1080px] items-center justify-between'>

            <div className='absolute bottom-10 left-0 right-0 text-white text-center flex flex-col w-full justify-center items-center transition-opacity duration-500'>
              <div className='font-semibold text-5xl mx-4'>{titles[sliderIndex]}</div>
              <div className='font-light text-xl'>{subtitles[sliderIndex]}</div>
              <div className='flex m-2 px-5 w-fit max-sm:w-fit max-w-[380px] h-[60px] max-md:w-[350px] text-center rounded-full backdrop-blur-lg border border-white p-2 text-[12px] max-md:text-[10px] items-center justify-center'>
                <img src={devices} alt='devices' className='h-[80%] mr-2' />
                <div className='relative mx-2 pr-2 rounded-[5px] border border-gray-700 gap-4'>
                  <input
                    type='email'
                    placeholder='Enter your email address'
                    className='w-full bg-transparent text-white h-[35px] px-[8px] pr-[30px] outline-none'
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <img
                    src={submit}
                    alt='submit'
                    className='absolute top-1 right-0 zoom-effect cursor-pointer h-[30px] mx-1'
                    onClick={handleEmailSubmit}
                  />
                </div>
                {/* <div className='flex space-x-2'>
                  <a href='https://apps.apple.com/app/itrood/' target='_blank'>
                    <img src={appstore} alt='app store' className='h-[40px] zoom-effect' />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.oneinthebillions.itrood&hl=en&gl=US' target='_blank'>
                    <img src={playstore} alt='play store' className='h-[40px] zoom-effect' />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='#platforms' className='h-fit bg-gradient-to-t from-[#262626] from-2%'>
        <div className='flex max-sm:flex-col w-full gap-10 justify-center pt-8 pb-2'>
          <a href='https://app.itrood.com/' target='_blank' className='flex flex-col items-center'>
            <div className='text-center p-2 h-[200px] w-[280px]'>
              <img alt='iTrood - Stream, Client App' src={Stream} className='shrink-0 resize-none h-full overflow-hidden zoom-effect' />
            </div>
            <div className='text-center p-2 h-[65px] w-[280px]'>
              <div className='font-bold hover:underline'>iTrood</div>
              <div className='hover:text-blue-500'>Stream</div>
            </div>
            <a href='https://app.itrood.com' target='_blank' className='button autoGrow flex cursor-pointer mb-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-blue-600 hover:bg-[#080E51] bg-blue-500 p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Try iTrood Now!</a>
            {/* <Link to={`/stream`} className='glitchButton flex cursor-pointer mb-2 w-[128px] h-[40px] max-md:w-[100px] text-center rounded-[8px] hover:rounded-full border border-zinc-600 hover:bg-zinc-400 bg-zinc-500 p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Learn More</Link> */}
          </a>
          <a href='https://distribution.itrood.com/' target='_blank' className='flex flex-col items-center'>
            <div className='text-center p-2 h-[200px] w-[280px]'>
              <img alt='iTrood Distribution' src={Resale} className='shrink-0 resize-none overflow-hidden zoom-effect' />
            </div>
            <div className='text-center p-2 h-[65px] w-[280px]'>
              <div className='font-bold hover:underline'>iTrood Distribution</div>
              <div className='hover:text-blue-500'>Resale</div>
            </div>
            <a href='https://distribution.itrood.com' target='_blank' className='button flex cursor-pointer mb-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-blue-600 hover:bg-[#080E51] bg-blue-500 p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Register your library</a>
            {/* <Link to={`/distribution`} className='glitchButton flex cursor-pointer mb-2 w-[128px] h-[40px] max-md:w-[100px] text-center rounded-[8px] hover:rounded-full border border-zinc-600 hover:bg-zinc-400 bg-zinc-500 p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Learn More</Link> */}
          </a>
          <a href='https://production.itrood.com/' target='_blank' className='flex flex-col items-center'>
            <div className='text-center p-2 h-[200px] w-[280px]'>
              <img alt='iTrood Production' src={Release} className='shrink-0 resize-none overflow-hidden zoom-effect' />
            </div>
            <div className='text-center p-2 h-[65px] w-[280px]'>
              <div className='font-bold hover:underline'>iTrood Production</div>
              <div className='hover:text-blue-500'>Release</div>
            </div>
            <a href='https://production.itrood.com' target='_blank' className='button flex cursor-pointer mb-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-blue-600 hover:bg-[#080E51] bg-blue-500 p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Became a producer</a>
            {/* <Link to={`/production`} className='glitchButton flex cursor-pointer mb-2 w-[128px] h-[40px] max-md:w-[100px] text-center rounded-[8px] hover:rounded-full border border-zinc-600 hover:bg-zinc-400 bg-zinc-500 p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Learn More</Link> */}
          </a>
        </div>
      </section>

      <section id='#tv' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex max-sm:flex-col-reverse w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex flex-col w-[50%] justify-center max-md:w-full p-10 max-md:p-1'>
              <div className='font-bold text-5xl max-md:text-4xl m-3 max-md:m-1'>Enjoy on your TV</div>
              <div className='font-normal text-xl max-md:text-lg m-3 max-md:m-1'>Watch on Smart TVs, Playstation, Xbox, Chromecast, Apple TV, Blu-ray players, and more.</div>
            </div>
            <div className='flex w-[50%] justify-center max-sm:w-full flex-shrink-0 relative'>
              <img alt='iTrood - Smart Tv' src={tv} className='shrink-0 resize-none h-full w-[80%]' />
              <video
                src={SmartTv}
                className='absolute z-[-1] overflow-hidden top-[16%] left-[20%] max-w-[73%] max-h-[64%] object-cover'
                autoPlay
                muted
                loop
                playsInline
              />
            </div>
          </div>
        </div>
      </section>
      <section id='#offline' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex max-sm:flex-col w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex w-[50%] justify-center max-sm:w-full flex-shrink-0'>
              <img alt='iTrood - Offline download' src={offline} className='shrink-0 resize-none object-cover h-[320px] w-fit' />
            </div>
            <div className='flex flex-col w-[50%] justify-center max-md:w-full p-10 max-md:p-1'>
              <div className='font-bold text-5xl max-md:text-4xl m-3 max-md:m-1'>Download to watch offline</div>
              <div className='font-normal text-xl max-md:text-lg m-3 max-md:m-1'>Save your favorites easily and always have something to watch.</div>
            </div>
          </div>
        </div>
      </section>
      <section id='#kids' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex max-sm:flex-col-reverse w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex flex-col w-[50%] justify-center max-md:w-full p-10 max-md:p-1'>
              <div className='font-bold text-5xl max-md:text-4xl m-3 max-md:m-1'>iTrood kids</div>
              <div className='font-bold text-xl ml-3 mt-3'>Create profiles for kids</div>
              <div className='font-normal text-xl max-md:text-lg m-3 max-md:m-1'>Send kids on adventures with their favorite characters in a space made just for them—free with your membership.</div>
              <div className='flex gap-5 mx-3 max-md:mx-1'>
                {/* <Link to={`/kids`} className='glitchButton flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-zinc-600 hover:bg-zinc-400 bg-zinc-500 p-2 text-white text-[12px] max-md:text-[10px] items-center justify-center'>Learn More</Link> */}
                <a href='https://kids.itrood.com' target='_blank' className='button flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-blue-600 hover:bg-blue-400 bg-blue-500 p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Try iTrood kids Now!</a>
              </div>
            </div>
            <div className='flex w-[50%] justify-center max-sm:w-full flex-shrink-0'>
              <ActiveImage alt='iTrood Kids' src={kids} className='shrink-0 resize-none h-[314px] w-fit relative inline-block' />
            </div>
          </div>
        </div>
      </section>
      <section id='#watch-everywere' className='h-fit'>
        <div className='max-md:px-4 px-10 py-4 flex justify-center items-center'>
          <div className='flex flex-col w-[1080px] max-md:w-full items-center justify-between'>
            <div className='flex w-full justify-center max-sm:w-full flex-shrink-0'>
              <img alt='iTrood icon - watch everywere' src={watch} className='shrink-0 resize-none h-[100px] w-fit' />
            </div>
            <div className='flex flex-col w-full justify-center max-md:w-full px-10 max-md:p-1'>
              <div className='font-normal justify-center text-center text-xl max-md:text-lg m-3 max-md:m-1'>Watch everywhere Stream unlimited movies and TV shows on your phone, tablet, laptop, and TV.</div>
              <div className='flex justify-center mx-3 max-md:mx-1'>
                <a href='https://watch.itrood.com' target='_blank' className='growButton flex cursor-pointer my-2 w-[188px] h-[50px] max-md:w-[128px] text-center rounded-[12px] border border-[#7C86E7] hover:bg-[#0D1784] bg-[#080E51] p-2 text-[12px] max-md:text-[10px] items-center justify-center'>Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='bg-[#242424] h-[10px] w-full my-5' />
      <section id='#trusted by these merchants' className='h-fit'>
        <div className='px-10 max-sm:px-5 py-4 flex flex-col justify-center items-center'>
          <div className='flex flex-col justify-center items-center w-[1080px] max-sm:w-full'>
            <div className='flex flex-col w-[974px] max-sm:w-full'>
              <div className='font-bold text-center text-5xl max-md:text-4xl m-3 max-md:m-1'>Frequently Asked Questions</div>
              <FAQ />
            </div>
          </div>
        </div>
      </section>
      <div className='bg-[#242424] h-[10px] w-full my-5' />
      <Footer />
    </div>
  );
};

export default Home;