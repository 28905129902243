import React, { useState } from 'react';
import { Facebook, Instagram, LinkedIn, logo2, X, Medium } from '../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { navLinks } from './Navbar';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(item => `/${item.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  return (
    <div className='flex gap-4 p-2 bg-black text-white px-4 justify-center'>
      <div className='flex flex-col w-[1080px] max-md:w-full max-md:px-3 items-center justify-between'>

        <div className='flex bg-[#242424] w-full gap-4 rounded-md'>
          <img src={logo2} className='w-[120px] h-[120px] max-sm:w-[100px] max-sm:h-[100px]' />
        </div>
        <div className='flex bg-[#242424] max-sm:flex-col-reverse w-full max-sm:justify-center max-sm:items-center gap-4 mt-2 h-40 rounded-md'></div>
        <div className='flex bg-[#242424] max-sm:flex-col-reverse w-full max-sm:justify-center items-center gap-4 mb-2 mt-2 min-h-10 py-1 rounded-md'>
          <div className='w-[60%] max-sm:w-full max-sm:text-center mx-2'>© 2024 iTrood, Inc. All rights reserved</div>
          <div className='flex w-[35%] max-sm:w-full justify-between max-sm:justify-around gap-4'>
            <Link to={`/terms-and-conditions`} className='hover:text-blue-500'>Terms & Conditions</Link>
            <Link to={`/privacy-policy`} className='hover:text-blue-500'>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;