import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 400px; /* Adjust as needed */
  height: 314px; /* Adjust as needed */
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.6s ease-out;
  transform-origin: center center;
`;

const ActiveImage = ({ src, alt }) => {
  const [transform, setTransform] = useState('scale(1)');

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setTransform(`scale(1.05) translate(${x / 10}%, -${y / 20}%)`);
  };

  const handleMouseLeave = () => {
    setTransform('scale(1)');
  };

  return (
    <Container>
      <Image
        src={src}
        alt={alt}
        style={{ transform }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </Container>
  );
};

export default ActiveImage;