import React from 'react';
import { logo } from './assets';

const Intro = () => {
  return (
    <div className='h-screen w-screen flex flex-col bg-black justify-center items-center'>
      <img src={logo} className='w-[200px]' alt='iTrood - logo' />
    </div>
  )
};

export default Intro;
