import React, { useState } from "react";
import "./styles.css";

export default function FAQ() {
    const [faqs, setFaqs] = useState([
        {
            question: "What is iTrood?",
            answer: [
                "iTrood is a digital movie distribution company that offers streaming services, individual movie sales, and a distribution platform for local agents.",
                "We aim to revolutionize the movie distribution landscape by leveraging AI technology for translating movies with voice lip-syncing.",
                "Our platform supports filmmakers in reaching wider audiences and overcoming language barriers."
            ],
            open: false
        },
        {
            question: "How does iTrood's AI technology work?",
            answer: [
                "iTrood's AI technology translates movies while maintaining accurate voice lip-syncing, making it easier for audiences to enjoy films in their preferred language.",
                "This advanced AI ensures seamless and natural translations that match the original actors' lip movements.",
                "It helps in providing a more immersive viewing experience for global audiences."
            ],
            open: false
        },
        {
            question: "What services does iTrood offer?",
            answer: [
                "Streaming services for a wide range of movies and TV shows.",
                "Individual movie sales for users who prefer to own their favorite films.",
                "A distribution platform for local agents to promote and sell movies in their regions."
            ],
            open: false
        },
        {
            question: "How can filmmakers benefit from iTrood?",
            answer: [
                "Filmmakers can reach a global audience by utilizing iTrood's AI-powered translation and distribution services.",
                "Our platform provides tools and support for promoting and monetizing their films effectively.",
                "Filmmakers also have the opportunity to collaborate with local agents to maximize their film's reach and impact."
            ],
            open: false
        },
        {
            question: "Is iTrood available worldwide?",
            answer: [
                "Yes, iTrood is designed to cater to a global audience, providing access to a diverse range of films from different cultures and languages.",
                "Our AI translation technology ensures that language barriers are minimized, making films accessible to everyone."
            ],
            open: false
        },
        {
            question: "How can I access iTrood's services?",
            answer: [
                "You can access iTrood's services through our website or mobile app, available on both iOS and Android platforms.",
                "Simply sign up for an account to start exploring our extensive library of films and streaming options."
            ],
            open: false
        },
        {
            question: "What payment methods does iTrood accept?",
            answer: [
                "iTrood accepts various payment methods, including credit/debit cards, PayPal, and mobile payment options.",
                "We aim to provide a seamless and secure payment experience for our users."
            ],
            open: false
        },
        {
            question: "Does iTrood offer customer support?",
            answer: [
                "Yes, iTrood provides dedicated customer support to assist with any queries or issues you may encounter.",
                "You can reach our support team through email, phone, or live chat on our website."
            ],
            open: false
        },
        {
            question: "Can I cancel my iTrood subscription at any time?",
            answer: [
                "Yes, you can cancel your iTrood subscription at any time through your account settings.",
                "There are no long-term commitments, and you can enjoy our services on a month-to-month basis."
            ],
            open: false
        },
        {
            question: "How does iTrood ensure content quality and security?",
            answer: [
                "iTrood uses advanced encryption and security measures to protect user data and ensure a safe viewing experience.",
                "We also curate a high-quality selection of films, ensuring that our content meets the standards and preferences of our diverse audience."
            ],
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(prevFaqs =>
            prevFaqs.map((faq, i) => ({
                ...faq,
                open: i === index ? !faq.open : false
            }))
        );
    };

    const FAQUI = ({ faq, index }) => {
        return (
            <div
                className={"faq " + (faq.open ? "open" : "")}
                key={index}
            >
                <div onClick={() => toggleFAQ(index)} className="faq- question faq-zoom-effect bg-[#262626] flex p-2 rounded-[10px] min-h-[60px] items-center font-bold text-large mb-1">{faq.question}</div>
                <div className="faq-answer">
                    {faq.answer.map((answer, idx) => (
                        <p key={idx} className="mb-5 px-5">{answer}</p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <FAQUI faq={faq} index={index} key={index} />
                ))}
            </div>
        </div>
    );
}
