import React, { useState, useEffect, useContext } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Intro from "./Intro";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Home from "./screens/Home";
import Pricing from "./screens/Pricing";
import OurProducts from './screens/OurProducts';
import OurStory from './screens/OurStory';
import Blog from './screens/Blog';
import ContactUs from './screens/ContactUs';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsAndConditions from './screens/TermsAndConditions';
import OrderPayment from './screens/OrderPayment';

import ErrorBoundary from "./ErrorBoundary";

const Layout = () => {
  return (
    <>
      <div className={`h-screen w-screen bg-[#000000] text-white`}>
        <div className={`flex flex-col w-full h-full fixed`}>
          <Navbar />
          <div className="flex flex-col h-full w-full justify-between flex-1 overflow-y-auto overflow-x-hidden scrollbar-hide">
            <div className={`h-full w-full`}>
              <Outlet />
              {/* <Footer /> */}
            </div>
            {/* <Footer /> */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      // {
      //   path: "/pricing",
      //   element: <Pricing />,
      // },
      // {
      //   path: "/our-story",
      //   element: <OurStory />,
      // },
      // {
      //   path: "/blog",
      //   element: <Blog />,
      // },
      // {
      //   path: "/contact-us",
      //   element: <ContactUs />,
      // },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      
    ],
  },
  {
    element: <OrderPayment />,
    children: [
      {
        path: "/app/orderPayment/:orderId",
        element: <OrderPayment />,
      },
    ],
  }
]);

function App() {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      <div className="">
        {showIntro ? <Intro /> : <RouterProvider router={router} />}
      </div>
    </div>
  );
}

export default App;